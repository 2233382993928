import axios from "axios";

async function SendCustomerEmail(data) {
  const formData = new FormData();

  const newInfo = {
    email: data.email,
  };

  formData.append("data", JSON.stringify(newInfo));

  const options = {
    method: "POST",
    data: formData,
    url: "https://techhype.herokuapp.com/api/customers",
  };

  try {
    const response = await axios(options);
    const data = response.data;

    if (data) {
      return { success: "Success", data: data };
    }
    if (!data) {
      return { failed: "Failed", data: data };
    }
  } catch (error) {
    return { sucess: false, error: error };
  }
}

export default SendCustomerEmail;
