import React, {useRef, useEffect} from "react"

const PhoneAnimation = (props) => {


  return (
    <div className="phone-grapich"
    dangerouslySetInnerHTML={{ __html: `
    <video
      loop
      muted="true"
      autoPlay
      playsinline
      src="videos/animation-video.webm"
      class="app__backgroundVideo"
    />,
  ` }}></div>
  );
};

export default PhoneAnimation;
