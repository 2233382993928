import { useState, useContext } from "react";
import Layout from "components/Layout";
import PhoneAnimation from "components/PhoneAnimation";
import { Button } from "techhype-components";
import NotificationModal from "components/NotificationModal";
import { LangContext } from "utils/LangContext";
import { content } from "constants/content";

function Home() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [lang, setLang] = useContext(LangContext);

  return (
    <Layout>
      <div className="header">
        <section className="heading">
          <h1>
            {content[lang]["heading1"]}{" "}
            <span className="gradient-title">{content[lang]["heading2"]}</span>{" "}
            {content[lang]["heading3"]}
          </h1>
          <p>{content[lang]["subHeading"]}</p>
          <Button onClick={handleOpen}>{content[lang]["noticeButton"]}</Button>
        </section>
        <PhoneAnimation />
      </div>
      <section className="comming-soon">
        <h2>{content[lang]["commingSoon"]}</h2>
      </section>
      <NotificationModal handleClose={handleClose} open={open} />
    </Layout>
  );
}

export default Home;
