import { useState, useContext } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import InputAdornment from "@mui/material/InputAdornment";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";
import { useSpring, animated } from "@react-spring/web";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ThreeDots } from "react-loader-spinner";
import { Button } from "techhype-components";

import schema from "constants/schema";
import FormTextField from "components/FormTextField";
import SendCustomerEmail from "utils/SendCustomerEmail";
import AlertMessage from "components/AlertMessage";
import { LangContext } from "utils/LangContext";
import { content } from "constants/content";

const formStyle = {
  position: "relative",
  minWidth: "180px",
  maxWidth: "450px",
  width: "90%",
  bgcolor: "#1F2427",
  boxShadow: 24,
  p: 4,
  borderRadius: 4,
  color: "white",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  margin: "1em",
};

const modalStyle = {
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
};

const loadingBox = {
  position: "absolute",
  top: "50%",
  right: "50%",
  transform: "translate(-50%, -50%)",
}

export default function NotificationModal({ open, handleClose }) {
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [lang, setLang] = useContext(LangContext);

  const closeModal = () => {
    handleClose();
    reset();
    setSubmitted(false);
  };

  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  // Function that will run when form is submitted
  async function onSubmit(data) {
    setLoading(true);
    const send = await SendCustomerEmail(data);
    if (send.success) {
      setLoading(false);
      setSubmitted(true);
      reset();
    } else {
      setLoading(false);
      setSubmitted(false);
      setError(true);
    }
  }

  const slide = useSpring({
    from: { y: -200 },
    to: { y: 0 },
    reset: true,
    config: { duration: 400 },
  });

  const opacity = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    reset: true,
    config: { duration: 900 },
  });

  if (error) {
    return (
      <div className="centered-max">
        <AlertMessage
          variant="error"
          title="Error"
          message={content[lang]["submitError"]}
        />
      </div>
    );
  }

  if (loading) {
    return (
      <Box sx={loadingBox}>
        <ThreeDots
          height="80"
          width="80"
          radius="9"
          color="#54d4c6"
          ariaLabel="three-dots-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
      </Box>
    );
  }

  return (
    <Modal
      open={open}
      onClose={closeModal}
      aria-labelledby="notification-modal"
      aria-describedby="get-notification-for-launch-time"
      sx={modalStyle}
    >
      <Box
        sx={formStyle}
        component="form"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onSubmit)}
      >
        {!submitted ? (
          <>
            <Typography id="notification-modal" variant="h5" component="h2">
              {content[lang]["modalHeading"]}
            </Typography>
            <FormTextField
              id="email"
              type="email"
              label={content[lang]["modalInput"]}
              variant="standard"
              sx={{ mt: 2, mb: 4 }}
              fullWidth
              onInput={(e) =>
                (e.target.value = ("" + e.target.value).toLowerCase())
              }
              {...register("email")}
              error={Boolean(errors.email)}
              helperText={
                errors.email ? `${content[lang]["modalInputError"]}` : ""
              }
              InputProps={
                errors.email
                  ? {
                      endAdornment: (
                        <InputAdornment position="end">
                          <ErrorRoundedIcon color="error" />
                        </InputAdornment>
                      ),
                    }
                  : null
              }
            />
            <div onClick={closeModal} className="notification-close">
              <CloseIcon
                onClose={closeModal}
                sx={{ fontSize: 30, color: "white" }}
              />
            </div>
            <Button type="submit" size="small">
              {content[lang]["modalButton"]}
            </Button>
          </>
        ) : (
          <animated.div style={slide}>
            <animated.div style={opacity} className="confirmation-box">
              <div onClick={closeModal} className="confirmation-close">
                <CloseIcon
                  onClose={closeModal}
                  sx={{ fontSize: 30, color: "white" }}
                />
              </div>
              <DoneIcon sx={{ fontSize: 110, color: "#50CCC1" }} />
            </animated.div>
            <Typography
              id="confirmation"
              variant="h8"
              component="h3"
              textAlign={"center"}
            >
              {content[lang]["ModalConfirmation"]}
            </Typography>
          </animated.div>
        )}
      </Box>
    </Modal>
  );
}
